import { DealerContract } from './DealerContract';
import { Crop } from './Crop';

export interface Dealer {
  id: number;
  name: string;
  phone: string | null;
  email: string | null;
  position?: number;
  image?: string;
  contracts?: DealerContract[];
  tier: string | DealerType;
  crops?: Crop[];
  cropNames?: string[];
  activationId?: number | null;
  priority?: string | null;
  segment?: string | null;
  status?: string | null;
}

export enum DealerTier {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum DealerType {
  BUYER = 'buyer',
  RETAILER = 'retailer',
  ASSOCIATE = 'associate',
}

export const dealerTiers = [DealerTier.A, DealerTier.B, DealerTier.C];
export const dealerTiersChoices = [
  { id: DealerTier.A, name: DealerTier.A },
  { id: DealerTier.B, name: DealerTier.B },
  { id: DealerTier.C, name: DealerTier.C },
];

export type DealerPartial = Omit<Dealer, 'id' | 'crops'>;
