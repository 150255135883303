import { clsx } from 'clsx';
import { Packer } from 'docx';
import { Col, Row } from 'antd';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';

import { TableCrop } from './components/TableCrop';
import { CreditRisk, fixedRiskText, SurveyQuestion } from '@types';
import { TableFiscal } from './components/TableFiscal';
import { ExportRisk } from '@pages/credit-risk/export';
import { DownloadZone } from './components/DownloadZone';
import { DropdownTable } from './components/DropdownTable';
import { DownloadBlock } from './components/DownloadBlock';
import { RiskGeneral } from '@pages/credit-risk/RiskGeneral';
import { TablePastCycle } from './components/TablePastCycle';
import { TableCreditBureau } from './components/TableCreditBureau';
import { ScoreFromAdminRisk } from './components/ScoreFromAdminRisk';
import { TableProjectedCycle } from './components/TableProjectedCycle';
import { AddemVariablesTable } from './components/AddemVariablesTable';
import { TotalVariablesTable } from './components/TotalVariablesTable';
import { useDebounce, useIsFirstRenderTimeout, useRequest } from '@hooks';
import { TableCreditBureauScore } from './components/TableCreditBureauScore';
import { DocumentCreator } from '@pages/credit/CreditSurvey/document-creator';
import { Container, InitialCard, PageHeader, ScoreWidget } from '@components';
import {
  creditHttp,
  CreditReportTypes,
  creditRiskHttp,
  ReportDocumentsProps,
  surveyHttp,
} from '@network';
import cls from './analysis.module.css';
import { read } from '@amcharts/amcharts5/.internal/bundled/xlsx';

export const Analysis = ({ risk, readonly }: {risk: CreditRisk; readonly?: boolean}) => {
  const [riskCalculated, setRiskCalculated] = useState<CreditRisk>(risk);
  const [isReportOpened, setReportOpened] = useState(false);
  const [documents, setDocuments] = useState<ReportDocumentsProps | null>(null);
  const [creditDocs, setCreditDocs] = useState(0);
  const [guarantorsDocs, setGuarantorsDocs] = useState(0);
  const openReport = risk ? () => setReportOpened(true) : undefined;
  const [questions, setQuestions] = useState<SurveyQuestion[] | null>(null);
  const score = risk.riskScore || 0;
  const backTo = `/credit-risk/${risk.id}`;
  const leftCardsLabelGrid = { xs: 12, lg: 24, xl: 8, xxl: 7 };
  const leftCardsValueGrid = { xs: 12, lg: 24, xl: 16, xxl: 17 };

  const {
    field: { value: tablePastCycle, onChange: changeTablePastCycle },
  } = useController({ name: 'tablePastCycle' });
  const {
    field: { value: tableProjectedCycle, onChange: changeTableProjectedCycle },
  } = useController({ name: 'tableProjectedCycle' });
  const {
    field: { value: tableCrop, onChange: changeTableCrop },
  } = useController({ name: 'tableCrop' });
  const {
    field: { value: tableFiscal, onChange: changeTableFiscal },
  } = useController({ name: 'tableFiscal' });
  const {
    field: { value: tableCreditBureau, onChange: changeTableCreditBureau },
  } = useController({ name: 'tableCreditBureau' });
  const {
    field: { value: tableCreditBureauScore, onChange: changeTableCreditBureauScore },
  } = useController({ name: 'tableCreditBureauScore' });
  const {
    field: { value: addemVariables, onChange: changeAddemVariablesTable },
  } = useController({ name: 'addemVariables' });
  const {
    field: { value: totalVariables, onChange: changeTotalVariablesTable },
  } = useController({ name: 'totalVariables' });
  const {
    field: { value: replyOther, onChange: changeReplyOther },
  } = useController({ name: 'replyOther' });
  const {
    field: { value: replyScore, onChange: changeReplyScore },
  } = useController({ name: 'replyScore' });

  const debouncedTablePastCycle = useDebounce(tablePastCycle, 500);
  const debouncedTableProjectedCycle = useDebounce(tableProjectedCycle, 500);
  const debouncedTableCrop = useDebounce(tableCrop, 500);
  const debouncedTableFiscal = useDebounce(tableFiscal, 500);
  const debouncedTableCreditBureau = useDebounce(tableCreditBureau, 500);
  const debouncedTableCreditBureauScore = useDebounce(tableCreditBureauScore, 500);
  const debouncedTotalVariables = useDebounce(totalVariables, 500);
  const isFirstRender = useIsFirstRenderTimeout(2000);

  const {
    submit: downloadBureauReport, loading: downloadBureauReportLoading,
  } = useRequest(async () => {
    await creditHttp.downloadReportFile({
      type: CreditReportTypes.BUREAU_REPORT,
      creditId: risk.creditId,
    });
  });

  const {
    submit: downloadSinectaReport, loading: downloadSinectaReportLoading,
  } = useRequest(async () => {
    await creditHttp.downloadReportFile({
      type: CreditReportTypes.SINECTA_REPORT,
      creditId: risk.creditId,
    });
  });

  const {
    submit: downloadEkatenaReport, loading: downloadEkatenaReportLoading,
  } = useRequest(async () => {
    await creditHttp.downloadReportFile({
      type: CreditReportTypes.EKATENA_REPORT,
      creditId: risk.creditId,
    });
  });

  const { submit: downloadDocuments, loading: downloadDocumentsLoading } = useRequest(async () => {
    await creditHttp.downloadCreditDocuments(risk.creditId);
  });

  const generateDocx = () => {
    if (questions) {
      const documentCreator = new DocumentCreator();
      const doc = documentCreator.createFromQeustions(questions, risk.credit);

      Packer.toBlob(doc).then(blob => {
        saveAs(blob, `${risk.credit.user.fullName}. Questionario of Credit ${risk.credit.code}.docx`);
        console.log('Document created successfully');
      });
    }
  };

  const {
    submit: downloadAllGuarantorDocuments,
    loading: downloadAllGuarantorDocumentsLoading,
  } = useRequest(async () => {
    await creditHttp.downloadAllGuarantorDocuments(risk.creditId, risk.credit.code);
  });

  const getDocsCounter = (type: CreditReportTypes): number | undefined => {
    return !documents ? undefined : documents[type].length;
  };

  useEffect(() => {
    (async () => {
      try {
        const loadedSurvey = await surveyHttp.getCreditSurvey(risk.creditId);
        const docsData: ReportDocumentsProps = await creditHttp.reportFiles(risk.creditId);
        const creditDocsCounter: number = await creditHttp.creditDocuments(risk.creditId);
        const guarantorsDocsCounter: number = await creditHttp.guarantorsDocuments(risk.creditId);

        setGuarantorsDocs(typeof creditDocsCounter === 'number' ? creditDocsCounter : 0);
        setCreditDocs(typeof guarantorsDocsCounter === 'number' ? guarantorsDocsCounter : 0);

        if (loadedSurvey && loadedSurvey?.surveyQuestions.length) {
          setQuestions(loadedSurvey.surveyQuestions);
        }

        if (docsData) {
          setDocuments(docsData);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [risk]);

  useEffect(() => {
    !isFirstRender && (async () => {
      try {
        const calculated = await creditRiskHttp.calculate(risk.id, {
          tablePastCycle,
          tableProjectedCycle,
          tableCrop,
          tableFiscal,
          tableCreditBureau,
          tableCreditBureauScore,
          totalVariables,
        });
        const s = (obj: any) => JSON.stringify(obj || {});

        if (!calculated) {
          throw new Error('No result');
        }

        if (s(tablePastCycle) !== s(calculated.tablePastCycle)) {
          changeTablePastCycle(calculated.tablePastCycle);
        }
        if (s(tableProjectedCycle) !== s(calculated.tableProjectedCycle)) {
          changeTableProjectedCycle(calculated.tableProjectedCycle);
        }
        if (s(tableCreditBureau) !== s(calculated.tableCreditBureau)) {
          changeTableCreditBureau(calculated.tableCreditBureau);
        }
        if (s(tableFiscal) !== s(calculated.tableFiscal)) {
          changeTableFiscal(calculated.tableFiscal);
        }
        setRiskCalculated(calculated);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    debouncedTablePastCycle,
    debouncedTableProjectedCycle,
    debouncedTableCrop,
    debouncedTableFiscal,
    debouncedTableCreditBureau,
    debouncedTableCreditBureauScore,
    debouncedTotalVariables,
    isFirstRender,
  ]);

  const isFixed = risk.isFixed || !!readonly;

  return (
    <>
      <PageHeader
        title="Analysis"
        linkText="Back to risk page"
        linkUrl={backTo}
      />

      <Container max>
        <Row gutter={24} className={cls.row}>
          <Col xs={24} xl={16} className={cls.leftCol}>
            <Row className={cls.row}>
              <Col xs={24}>
                {risk ? <RiskGeneral
                  risk={risk}
                  isFixed={isFixed}
                  labelGrid={leftCardsLabelGrid}
                  valueGrid={leftCardsValueGrid}
                /> : null}
              </Col>
            </Row>

            <Row className={cls.row}>
              <Col xs={24}>
                <InitialCard left="Income Verification" riskPadding
                  lockText={risk.isFixed ? fixedRiskText : null}>
                  <Row gutter={16} className={cls.row} style={{ marginTop: 22 }}>
                    <Col xs={24} sm={12} className={cls.tableCol}>
                      <TablePastCycle
                        data={tablePastCycle}
                        onChange={changeTablePastCycle}
                        title="Past Cycle"
                        isFixed={isFixed}
                      />
                    </Col>
                    <Col xs={24} sm={12} className={cls.tableCol}>
                      <TableProjectedCycle
                        data={tableProjectedCycle}
                        onChange={changeTableProjectedCycle}
                        title="Projected Cycle"
                        isFixed={isFixed}
                      />
                    </Col>
                  </Row>
                </InitialCard>
              </Col>
            </Row>
          </Col>

          <Col xs={24} xl={8} className={cls.rightCol}>
            <div className={clsx(cls.colored, cls.bordered, cls.aside, cls.padding)}>
              <InitialCard center="Score Total" riskPadding>
                <ScoreWidget
                  value={score}
                  percents={score <= 89 ? 10 : 90}
                  className={cls.scoreWidget}
                  onClick={openReport}
                />
              </InitialCard>

              <DownloadZone>
                <DownloadBlock
                  title="Report - Bureau"
                  onClick={downloadBureauReport}
                  isLoading={downloadBureauReportLoading}
                  counter={getDocsCounter(CreditReportTypes.BUREAU_REPORT)}
                />
                <DownloadBlock
                  title="Report - Field"
                  onClick={downloadSinectaReport}
                  isLoading={downloadSinectaReportLoading}
                  counter={getDocsCounter(CreditReportTypes.SINECTA_REPORT)}
                />
                <DownloadBlock
                  title="Report - SAT"
                  onClick={downloadEkatenaReport}
                  isLoading={downloadEkatenaReportLoading}
                  counter={getDocsCounter(CreditReportTypes.EKATENA_REPORT)}
                />
                <DownloadBlock
                  title="Docs - Client"
                  onClick={downloadDocuments}
                  isLoading={downloadDocumentsLoading}
                  counter={creditDocs}
                />
                <DownloadBlock
                  title="Docs - Guarantors"
                  onClick={downloadAllGuarantorDocuments}
                  isLoading={downloadAllGuarantorDocumentsLoading}
                  counter={guarantorsDocs}
                />
                <DownloadBlock
                  title="Survey"
                  onClick={generateDocx}
                  isLoading={false}
                />
              </DownloadZone>
            </div>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Overall Fiscal Income & Expenses" riskPadding
              lockText={risk.isFixed ? fixedRiskText : null}>
              <TableFiscal
                data={tableFiscal}
                onChange={changeTableFiscal}
                isFixed={isFixed}
              />
            </InitialCard>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Fiscal Income of Crop to Finance" riskPadding
              lockText={risk.isFixed ? fixedRiskText : null}>
              <TableCrop
                data={tableCrop}
                onChange={changeTableCrop}
                isFixed={isFixed}
              />
            </InitialCard>
          </Col>
        </Row>

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Credit bureau" riskPadding
              lockText={risk.isFixed ? fixedRiskText : null}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={14}>
                  <TableCreditBureau
                    data={tableCreditBureau}
                    onChange={changeTableCreditBureau}
                    isFixed={isFixed}
                  />
                </Col>
                <Col xs={24} sm={24} md={10}>
                  <TableCreditBureauScore
                    data={tableCreditBureauScore}
                    onChange={changeTableCreditBureauScore}
                    isFixed={isFixed}
                  />
                </Col>
              </Row>
            </InitialCard>
          </Col>
        </Row>

        <ScoreFromAdminRisk
          data={replyScore}
          onChange={changeReplyScore}
          isFixedReplies={isFixed}
        />

        <Row className={clsx(cls.row, cls.marginTop)}>
          <Col xs={24} className={cls.row}>
            <InitialCard left="Other variables" riskPadding
              lockText={risk.isFixed ? fixedRiskText : null}>
              <Row className={cls.row} gutter={{ lg: 24 }}>
                <Col xs={24} xl={12} className={cls.row}>
                  <DropdownTable
                    data={replyOther}
                    onChange={changeReplyOther}
                    isFixedReplies={isFixed}
                  />
                </Col>
                <Col xs={24} xl={12} className={cls.row}>
                  {risk.showAddemVariables && (
                    <AddemVariablesTable
                      data={addemVariables}
                      onChange={changeAddemVariablesTable}
                      readonly={readonly}
                    />
                  )}

                  <TotalVariablesTable
                    data={totalVariables}
                    calcData={riskCalculated.totalVariables}
                    onChange={changeTotalVariablesTable}
                    readonly={readonly}
                  />

                  <div className={clsx(cls.colored, cls.bordered, cls.padding, cls.marginTop)}>
                    <ScoreWidget
                      value={score}
                      percents={score <= 89 ? 10 : 90}
                      className={cls.scoreBottomWidget}
                      onClick={openReport}
                    />
                  </div>
                </Col>
              </Row>
            </InitialCard>
          </Col>
        </Row>
      </Container>

      <ExportRisk
        creditId={risk.creditId}
        isOpened={isReportOpened}
        setOpened={setReportOpened}
      />
    </>
  );
};
