import { Col, Row } from 'antd';
import { makeStyles } from '@mui/styles';
import axios from 'axios';

import { CreditRisk } from '@types';
import { useEffect, useState } from 'react';
import { ScoreWidget } from '@components';
import { moneyFormatter } from '@utils';

export const RiskHero = ({ risk }: {risk: CreditRisk}) => {
  const cls = useStyles();
  const [avatar, setAvatar] = useState<string>();

  const credit = risk.credit;
  const user = credit?.user;
  const score = risk?.riskScore || 0;

  useEffect(() => {
    user && user.avatar && (async () => {
      const avatarBase64 = await getBase64(user.avatar as string);
      setAvatar(avatarBase64);
    })();
  }, [user]);

  return (
    <div className={cls.info}>
      <Row style={{ alignItems: 'stretch' }}>
        <Col md={12} sm={24}>
          <div className={cls.infoTitle}>Aplicación</div>
          <Row className={cls.infoRow}>
            <Col md={8} sm={24} className={cls.infoBold}>ID Verqor</Col>
            <Col md={16} sm={24} className={cls.infoBold}>{credit?.code}</Col>
          </Row>
          <Row className={cls.infoRow}>
            <Col md={8} sm={24}>Cliente</Col>
            <Col md={16} sm={24}>{credit?.accreditedName}</Col>
          </Row>
          <Row className={cls.infoRow}>
            <Col md={8} sm={24}>Retenedores</Col>
            <Col md={16} sm={24}>{risk.buyer}</Col>
          </Row>
          <Row className={cls.infoRow}>
            <Col md={8} sm={24}>Proveedores</Col>
            <Col md={16} sm={24}>{risk.supplier}</Col>
          </Row>
          <Row className={cls.infoRow}>
            <Col md={8} sm={24}>Estado</Col>
            <Col md={16} sm={24}>{user?.state?.name}</Col>
          </Row>
          <Row className={cls.infoRow}>
            <Col md={8} sm={24}>Cultivo</Col>
            <Col md={16} sm={24}>{(user?.crops || []).map(crop => crop.nameMx).join(', ')}</Col>
          </Row>
          <Row className={cls.infoRow}>
            <Col md={8} sm={24}>Canal</Col>
            <Col md={16} sm={24}>{user?.sourceEn}</Col>
          </Row>
          <Row className={cls.infoRow}>
            <Col md={24} sm={24}>
              {user?.isCompany ? 'PM - Persona Moral' : 'PF - Persona Fisica'}
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <div className={cls.score}>
            <div className={cls.scoreTitle}>Score Total</div>
            <ScoreWidget
              deniedText="Denegado"
              approvedText="Aprobado"
              value={score}
              percents={score <= 89 ? 10 : 90}
              avatarUrl={avatar || undefined}
            />
            <div className={cls.scoreMoney}>
              Monto solicitado de {moneyFormatter.format(credit?.requestAmount || 0)}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const useStyles = makeStyles({
  info: {
    marginTop: 15,
    padding: '16px 18px',
    borderRadius: 5,
    border: '1px solid #042E6B',
    fontSize: 14,
    fontWeight: 400,
  },
  infoRow: {
    marginTop: 2,
  },
  infoTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: -4,
    marginBottom: 13,
  },
  infoBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  score: {
    borderRadius: 8,
    border: '1px solid #042E6B',
    background: '#F0F3FB',
    padding: '10px 36px 18px',
    height: '100%',
  },
  scoreTitle: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  scoreMoney: {
    marginTop: 15,
    fontSize: 13,
    fontWeight: 400,
    textAlign: 'center',
  },
});

const getBase64 = (url: string): Promise<string> => axios
  .get(url, { responseType: 'arraybuffer' })
  .then(response => {
    // @ts-ignore
    const b64encoded = btoa([].reduce.call(
      new Uint8Array(response.data),
      (p: any, c: any) => p + String.fromCharCode(c),
      '',
    ));
    const mimetype = 'image/jpeg';
    return `data:${mimetype};base64,${b64encoded}`;
  });
