import { clsx } from 'clsx';
import { RcFile } from 'antd/lib/upload';

import { convertFileToBase64, FileUpload, InputText } from '@components';
import { UploadIcon } from '@components/Crops';
import { showAxiosError } from '@utils';
import { Crop, dealerTiers, DealerType } from '@types';
import cls from './buyer-form.module.css';
import { InputSelect, InputSelectAutocomplete } from '@components/inputs/InputSelect';
import React, { useEffect, useState } from 'react';
import { cropHttp } from '@network';
import { financeSettingsHttp } from '@network/finance-settings-http';
import { Select } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MAX_IMAGE_SIZE = 5;

interface CreatorFormProps {
  formValues: any;
  setFormValues: any;
  showErrors: boolean;
  dealerType: DealerType;
}

export const BuyerForm = ({
  formValues, setFormValues, showErrors, dealerType,
}: CreatorFormProps) => {
  const [crops, setCrops] = useState<Crop[]>([]);
  const [activations, setActivations] = useState<any[]>([]);
  const showNameError = (formValues.name || '').length === 0 && showErrors;
  const showPhone = dealerType === DealerType.ASSOCIATE;
  const showPhoneError = showPhone && (formValues.phone || '').length === 0 && showErrors;
  const showCrops = dealerType === DealerType.BUYER;
  const showTier = dealerType === DealerType.BUYER;
  const showActivation = true;
  const showTierError = showTier && (formValues.tier || '').length === 0 && showErrors;

  useEffect(() => {
    (async () => {
      const allCrops: Crop[] = await cropHttp.getAll();
      setCrops(allCrops);

      const allActivations = await financeSettingsHttp.getActivations();
      setActivations(allActivations);
    })();
  }, []);

  const onChangeHandler = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeActivation = (activationId: number) => {
    setFormValues({ ...formValues, activationId });
  };

  const onImageClear = () => {
    setFormValues({
      ...formValues,
      image: '',
    });
  };

  const uploadPicture = async (rcFile: RcFile): Promise<any> => {
    try {
      const base64Img = await convertFileToBase64(rcFile) as string;

      if (base64Img) {
        setFormValues({
          ...formValues, image: base64Img as string,
        });
      }
    } catch (err: any) {
      showAxiosError(err);
    }
  };
  const maxLength = 66;

  const UploadContent = () => {
    return (
      <div className={cls.uploadContent}>
        <div className={cls.uploadContentBlock}>
          <div className={cls.uploadContentTitle}>Upload Image</div>
          <div className={cls.uploadContentIcon}>
            <UploadIcon />
          </div>
        </div>
        <div className={cls.uploadContentComment}>
          *File size should not exceed {MAX_IMAGE_SIZE}MB
        </div>
      </div>
    );
  };

  // priority options
  const priorityOptions = ['AAA', 'CyE', 'Strategic Partner'];
  if (formValues.priority && !priorityOptions.includes(formValues.priority)) {
    priorityOptions.unshift(formValues.priority);
  }

  // segment options
  const segmentOptions = dealerType === DealerType.BUYER
    ? ['Acopio', 'Afiliados', 'Agroinsumos', 'Aliado Corp', 'Comercializador', 'CPG', 'Exportadora', 'Empaque', 'FMCG', 'Seguros']
    : dealerType === DealerType.ASSOCIATE
      ? ['Afiliados', 'Agroinsumos']
      : ['Afiliados', 'Agroinsumos', 'Comercializador', 'CPG', 'Exportadora', 'Empaque', 'Sistemas de riego'];
  if (formValues.segment && !segmentOptions.includes(formValues.segment)) {
    segmentOptions.unshift(formValues.segment);
  }

  // status options
  const statusOptions = ['Closed', 'In negotiations', 'Not a match', 'Pending'];
  if (formValues.status && !statusOptions.includes(formValues.status)) {
    statusOptions.unshift(formValues.status);
  }

  return (
    <div className={cls.form}>
      {formValues.id
        ? <div className={cls.row}>
          <InputText
            name="id"
            label="ID"
            disabled
            value={formValues.id || ''}
            onChange={() => {}}
            className={cls.bordered}
          />
        </div>
        : null}
      <div className={cls.row}>
        <InputText
          name="name"
          label="Name*"
          value={formValues.name || ''}
          onChange={onChangeHandler}
          className={clsx(showNameError && cls.error)}
          required
          maxLength={maxLength}
        />
      </div>

      {showPhone && (
        <div className={cls.row}>
          <InputText
            name="phone"
            label="Phone*"
            value={formValues.phone || ''}
            onChange={onChangeHandler}
            className={clsx(showPhoneError && cls.error)}
            required
            maxLength={maxLength}
          />
        </div>
      )}

      {showCrops && (
        <div className={cls.row}>
          <InputSelect
            name="cropNames"
            label="Unique Crops"
            onChange={onChangeHandler}
            values={crops.map(crop => crop.nameMx)}
            selectedValue={formValues.cropNames || []}
            multiple
          />
        </div>
      )}

      {showTier && (
        <div className={cls.row}>
          <InputSelect
            name="tier"
            label="Buyer Tier*"
            onChange={onChangeHandler}
            values={dealerTiers}
            selectedValue={formValues.tier}
            className={clsx(showTierError && cls.error)}
          />
        </div>
      )}

      {showActivation && (
        <div className={cls.row}>
          <InputSelectAutocomplete
            label="Activation"
            onChange={onChangeActivation}
            options={activations.map(activation => ({
              value: activation.id,
              label: activation.activation,
            }))}
            selectedValue={formValues.activationId}
            displayEmpty
          />
        </div>
      )}

      {dealerType === DealerType.BUYER && (
        <div className={cls.row}>
          <InputSelect
            selectedValue={formValues.priority}
            name="priority"
            label="Type"
            onChange={onChangeHandler}
            values={priorityOptions}
            displayEmpty
          />
        </div>
      )}

      <div className={cls.row}>
        <InputSelect
          selectedValue={formValues.segment}
          name="segment"
          label="Segment"
          onChange={onChangeHandler}
          values={segmentOptions}
          displayEmpty
        />
      </div>

      <div className={cls.row}>
        <InputSelect
          selectedValue={formValues.status}
          name="status"
          label="Status"
          onChange={onChangeHandler}
          values={statusOptions}
          displayEmpty
        />
      </div>

      {formValues.image?.length ? (
        <div className={cls.uploadedBox} onClick={onImageClear}>
          <div className={cls.uploadedIcon}>
            <UploadIcon />
          </div>
          <img src={formValues.image} alt="" className={cls.image} />
        </div>
      ) : (
        <FileUpload
          uploadAction={uploadPicture}
          className={cls.uploadBox}
          type="image"
          content={<UploadContent />}
          size={MAX_IMAGE_SIZE}
        />
      )}
    </div>
  );
};
