import React from 'react';
import { ListProps, TextField } from 'react-admin';

import {
  TextInput,
  Grid,
  GridList,
  Forbidden,
  MoneyField,
  DateField,
  SelectArrayInput,
  DateRangeInput,
} from '@components';
import { useExporter, useRbacList } from '@hooks';
import { useFinanceCostSettings } from '../use-finance-cost-settings';
import { FinanceDocsField } from '@pages/finance-withdrawal/FinanceDocsField';
import cls from './FinanceCostList.module.css';

export const FinanceCostList = (props: ListProps) => {
  const rbacList = useRbacList();
  const settings = useFinanceCostSettings();
  const exporter = useExporter({
    filename: 'Cost Center',
    props: {
      'id': {
        label: 'ID',
      },
      'code': {
        label: 'Code',
      },
      'expense': {
        label: 'Expense',
        transform: 'money',
      },
      'costCategory.name': {
        label: 'Category',
      },
      'descriptionName': {
        label: 'Description',
      },
      'typeName': {
        label: 'Type',
      },
      'paymentDate': {
        label: 'Date',
        transform: 'date',
      },
      'paymentBank': {
        label: 'Payment',
      },
      'activation.activation': {
        label: 'Activation',
      },
      'documents': {
        label: 'Invoices',
        transform: 'files',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  return (
    <GridList
      {...props}
      exporter={exporter}
      className={cls.page}
      filters={[
        <TextInput source="q" key="q" variant="outlined" label="Search" alwaysOn />,
        <SelectArrayInput
          style={{ width: 250 }}
          label="Category"
          key="category"
          source="category"
          choices={settings?.categories || []}
          alwaysOn
          className="MS-field"
        />,
        <SelectArrayInput
          style={{ width: 250 }}
          label="Description"
          key="subcategory"
          source="subcategory"
          choices={settings?.subcategories || []}
          alwaysOn
          className="MS-field"
        />,
        <SelectArrayInput
          style={{ width: 250 }}
          label="Type"
          key="type"
          source="type"
          choices={settings?.types || []}
          alwaysOn
          className="MS-field"
        />,
        <DateRangeInput
          key="date"
          source="date"
          label="Date"
          defaultValue={false}
        />,
        <DateRangeInput key="createdAt" source="createdAt" label="Created At"
          defaultValue={false} />,
      ]}>
      <Grid
        rowClick="edit"
        className={cls.grid}
        defaultColumns={'code expense costCategory.name activation.activation descriptionName typeName paymentDate paymentBank'.split(' ')}
      >
        <TextField source="code" name="Code" />
        <MoneyField source="expense" label="Expense" />
        <TextField source="costCategory.name" label="Category" />
        <TextField source="descriptionName" label="Description" noWrap={false} />
        <TextField source="typeName" label="Type" />
        <DateField source="paymentDate" label="Date" />
        <TextField source="paymentBank" label="Payment" />
        <TextField source="activation.activation" label="Activation" />
        <FinanceDocsField source="documents" label="Invoices" modelName="Cost" />
        <DateField
          source="createdAt"
          label="Created at"
          locales="es-ES"
          options={{ timeZone: 'UTC' }}
        />
      </Grid>
    </GridList>
  );
};
