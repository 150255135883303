import React, { useEffect, useState } from 'react';
import { Checkbox, Select, Tag } from 'antd';
import { useController } from 'react-hook-form';

import { http } from '@network';
import { makeStyles } from '@mui/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface Props {
  ids: number[] | null;
  setIds: (ids: number[]) => void;
  reference?: string;
  optionKey?: string;
  optionLabel?: string;
  placeholder?: string;
}

export const ManyTagsControlledInput = ({
  ids, setIds, reference, optionKey, optionLabel, placeholder,
}: Props) => {
  const cls = useStyles();
  const [options, setOptions] = useState<CheckOption[]>([]);

  useEffect(() => {
    (async () => {
      const response = await http.get<any>(reference, {
        params: { _start: 0, _end: 9999999 },
      });
      const nextOptions: CheckOption[] = [];
      if (response.data && Array.isArray(response.data)) {
        (response.data ?? []).forEach((model: any) => {
          nextOptions.push({
            value: `${model[optionKey ?? 'id']}`,
            label: model[optionLabel ?? 'nameMx'] || model['name'] || model['title'],
          });
        });
      }
      nextOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      setOptions(nextOptions);
    })();
  }, []);

  const handleChange = (ids: string[]) => {
    if (Array.isArray(ids)) {
      const numberIds = ids
        .filter(id => +id && Number.isInteger(+id))
        .map(id => +id);
      setIds(numberIds);
    }
  };

  return (
    <Select
      mode="tags"
      style={{ width: '100%' }}
      placeholder={placeholder}
      value={(ids || []).map((v: number) => `${v}`)}
      onChange={handleChange}
      options={options}
      className={cls.select}
      filterOption={(input, option) =>
        `${option?.label}`.toLowerCase().includes(input.toLowerCase())}
      tagRender={(props) => (
        <Tag
          closeIcon={<CloseOutlinedIcon className={cls.closeIcon} />}
          defaultValue={props.value}
          closable={props.closable}
          onClose={props.onClose}
          style={{ marginRight: 5, marginTop: 2, marginBottom: 2 }}
        >{props.label}</Tag>
      )}
    />
  );
};

interface CheckOption {
  value: string,
  label: string,
}

const useStyles = makeStyles({
  select: {
    '& .ant-select-selector': {
      borderBottom: 'none !important',
      backgroundColor: 'transparent !important',
    },
  },
  closeIcon: {
    fontSize: 19,
    verticalAlign: 'middle',
    color: '#AAA',
    '&:hover': {
      color: '#000',
    },
  },
});
