import React, { MutableRefObject, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Modal } from 'antd';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { CreditRisk } from '@types';
import { RiskHero } from './RiskHero';
import { creditHttp } from '@network';
import { dateFormatter } from '@utils';
import { RiskTaxes } from './RiskTaxes';
import { RiskTerms } from './RiskTerms';
import { RiskCrops } from './RiskCrops';
import { RiskOther } from './RiskOther';
import { BlueButton } from '@components';
import { RiskVariables } from './RiskVariables';
import { VerqorLogo } from './icons/VarqorLogo';
import { RiskScoreVariables } from './RiskScoreVariables';
import { DownloadFileIcon } from './icons/DownloadFileIcon';
import { RiskCreditBureau } from '@pages/credit-risk/export/RiskCreditBureau';
import { RiskCreditBureauScore } from '@pages/credit-risk/export/RiskCreditBureauScore';

interface Props {
  creditId: number;
  isOpened: boolean;
  setOpened: (nextOpened: boolean) => void;
  isPrepared?: boolean;
  reportNodeRef?: MutableRefObject<ReactNode>;
}

const pageHeight = 1210;
const pagesTotal = 4;

const RiskReport = ({ risk, isExporting, isPrepared, reportNodeRef }: any) => {
  const cls = useStyles();

  return (
    <div id="export-risk"
      className={clsx(cls.box, isExporting && cls.boxExporting, isPrepared && cls.boxPrepared)}
      ref={reportNodeRef}>
      {/* PAGE 1 */}
      <div className={clsx(isExporting && cls.page)}>
        <Header page={isExporting ? 1 : undefined}>
          Fecha {dateFormatter.toDateSlash(new Date())}
        </Header>
        {/* INFO (Hero) */}
        <RiskHero risk={risk} />

        {/* Variables Financieras */}
        <RiskVariables risk={risk} />
      </div>

      {/* PAGE 2 */}
      <div className={clsx(isExporting && cls.page)}>
        {isExporting && <Header page={2} marginTop={5} />}

        {/* Condiciones de crédito */}
        <RiskTerms risk={risk} />

        {/* Ingresos/Gastos tributarios generales */}
        <RiskTaxes risk={risk} />

        {/* Fiscal & Supplier */}
        <RiskCrops risk={risk} />
      </div>

      {/* PAGE 3 */}
      <div className={clsx(isExporting && cls.page)}>
        {isExporting && <Header page={3} marginTop={5} />}

        {/* Buro de Crédito */}
        <RiskCreditBureau risk={risk} />

        {/* Buro de Crédito Score */}
        <RiskCreditBureauScore risk={risk} />

        {/* Score Variables */}
        <RiskScoreVariables risk={risk} />
      </div>

      {/* PAGE 4 */}
      <div className={clsx(isExporting && cls.page)}>
        {isExporting && <Header page={4} marginTop={10} />}

        {/* Otras variables (other) */}
        <RiskOther risk={risk} />
      </div>
    </div>
  );
};

export const ExportRisk = ({
  creditId, isOpened, setOpened, isPrepared, reportNodeRef,
}: Props) => {
  const [risk, setRisk] = useState<CreditRisk>();
  const [isExporting, setExporting] = useState(false);
  const cls = useStyles();

  const hideModal = () => setOpened(false);
  const pdfFilename = `credit_analysis_${risk?.credit?.code}.pdf`;

  useEffect(() => {
    creditId && (async () => {
      const nextRisk = await creditHttp.getRisk(creditId);
      nextRisk && setRisk(nextRisk);
    })();
  }, [creditId]);

  const downloadPdfDocument = async () => {
    setExporting(true);
    await new Promise((resolve) => setTimeout(resolve, 250));
    const div = document.getElementById('export-risk');

    try {
      div && html2canvas(div).then((canvas) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }

        doc.save(pdfFilename);
      });
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      {risk && isPrepared && (
        <RiskReport
          risk={risk}
          isExporting={isExporting}
          reportNodeRef={reportNodeRef}
          isPrepared={isPrepared}
        />
      )}
      <Modal
        open={isOpened}
        title={(
          <div className={cls.modalTitle}>
            <BlueButton
              onClick={downloadPdfDocument}
              className={cls.downloadButton}
            ><DownloadFileIcon /> Download PDF</BlueButton>
            <div>File name: {pdfFilename}</div>
          </div>
        )}
        footer={null}
        onCancel={hideModal}
        width={800}
        style={{ top: 20 }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
        closeIcon={<Close style={{ marginTop: 14 }} />}
      >
        {isOpened && !risk && (
          <div className={cls.box}>
            <h3>Risk Manager for this credit is not found</h3>
          </div>
        )}
        {isOpened && risk && (
          <RiskReport
            risk={risk}
            isExporting={isExporting}
            reportNodeRef={reportNodeRef}
          />
        )}
      </Modal>
    </>
  );
};

const useStyles = makeStyles({
  modalTitle: {
    display: 'flex',
    '& > div': {
      padding: '10px 30px',
      fontSize: 14,
    },
  },
  box: {
    marginTop: -20,
    padding: '20px 20px',
    fontFamily: 'Outfit, sans-serif',
    color: '#000',
    background: 'url("/bg/export-risk-bg.png") repeat-y top left',
    position: 'relative',
  },
  boxPrepared: {
    position: 'absolute',
    zIndex: '-9999',
  },
  boxExporting: {
    height: pageHeight * pagesTotal + 20,
  },
  page: {
    height: pageHeight,
  },
  title: {
    margin: '25px 0 5px',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: '#000',
  },
  titleLeft: {
    textAlign: 'left',
  },
  header: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 14,
  },
  headerText: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    fontSize: 13,
    fontWeight: 400,
  },
  headerPage: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: 10,
    fontWeight: 400,
    color: '#042E6B',
  },
  downloadButton: {
    width: 'auto !important',
    display: 'flex',
    alignItems: 'center',
  },
});

type HeaderProps = {page?: number, marginTop?: number};
const Header = ({ children, page, marginTop }: PropsWithChildren<HeaderProps>) => {
  const cls = useStyles();

  return (
    <div className={cls.header} style={{ marginTop }}>
      {page && <div className={cls.headerPage}>Page {page}</div>}
      <div className={cls.headerText}>{children}</div>
      <VerqorLogo />
    </div>
  );
};
