import { useEffect, useState } from 'react';
import { Link } from 'react-admin';
import { Button } from '@mui/material';

import { Order, User } from '@types';
import { ordersHttp } from '@network';
import { InitialCard, Loader } from '@components';
import cls from './user-initial.module.css';
import { useIsMounted } from '@hooks';

const FIRST_ORDERS = 3;

export const UserOrders = ({ user }: {user: User}) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [showMore, setShowMore] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    isMounted.current && (async () => {
      try {
        const nextOrders = await ordersHttp.getOrdersByUser(user.id);
        isMounted.current && setOrders(nextOrders.sort((a, b) => b.id - a.id));
      } finally {
        isMounted.current && setLoading(false);
      }
    })();
  }, [user.id]);

  const onViewMore = () => void setShowMore(true);

  const showOrders = orders.length > FIRST_ORDERS && !showMore
    ? orders.slice(0, FIRST_ORDERS)
    : orders;

  return (
    <InitialCard center="Orders">
      <div className={cls.orders}>
        {loading && <Loader center />}
        {showOrders.map(order => (
          <Link to={`/order/${order.id}`} key={order.id} className={cls.order} fullWidth>
            <Button color="secondary" variant="contained" className={cls.orderBtn}>
              Order - {order.id}
            </Button>
          </Link>
        ))}
        {orders.length > FIRST_ORDERS && !showMore && (
          <Link to={`/user/${user.id}?orders`}>
            <Button
              color="secondary"
              variant="outlined"
              className={cls.orderBtn}
              onClick={onViewMore}
            >
              view more ({orders.length - FIRST_ORDERS})
            </Button>
          </Link>
        )}
      </div>
    </InitialCard>
  );
};
