import { Link } from 'react-admin';
import { Button } from '@mui/material';

import { User } from '@types';
import { InitialCard } from '@components';
import cls from './user-initial.module.css';

export const UserLinks = ({ user }: { user: User }) => {

  // const historyCount = user.history?.length || 0;
  const moffinCount = (user.moffinProfiles || []).length;
  const hubspotCount = user.hubspotId ? 1 : 0;

  const buttons: {link: string; text: string}[] = [
    // { link: `/user/${user.id}?farms`, text: `Farms (${farmsCount})` },
    // { link: `/user/${user.id}?history`, text: `History (${historyCount})` },
    { link: `/user/${user.id}?moffin`, text: `Moffin Score (${moffinCount})` },
    { link: `/user/${user.id}?hubspot`, text: `Hubspot Export (${hubspotCount})` },
  ];

  return (
    <InitialCard>
      <div className={cls.links}>
        {buttons.map(btn => (
          <Link to={btn.link} key={btn.link} className={cls.link}>
            <Button color="secondary" variant="contained" className={cls.linkBtn}>
              {btn.text}
            </Button>
          </Link>
        ))}
      </div>
    </InitialCard>
  );
};
