import { useEffect, useMemo, useState } from 'react';
import { useRedirect } from 'react-admin';
import { Button } from '@mui/material';

import { showAxiosError } from '@utils';
import { creditHttp, CreditReportTypes } from '@network';
import { Credit, CreditReportFile, Doctype, SurveyQuestion } from '@types';
import { InitialPage } from '@components';
import {
  CreditReportSurvey,
  CreditReportFiles,
  CreditReportUser,
  CreditReportGuarantor,
} from './index';
import { RequestedAmount } from './requested-amount';
import cls from './credit-report.module.css';

export const YesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <rect x="1"
      y="1"
      width="18"
      height="18"
      rx="9"
      fill="#0C5541"
      stroke="#0C5541"
      strokeWidth="2" />
    <path d="M13.8179 9.04008L9.98256 12.8213C9.92197 12.881 9.85273 12.9249 9.77855 12.9554C9.55476 13.0444 9.29016 13.0005 9.10841 12.8213L7.18083 10.9209C6.93972 10.6832 6.93972 10.2968 7.18083 10.0591C7.42193 9.82144 7.81387 9.82144 8.05498 10.0591L9.5461 11.5292L12.945 8.17827C13.1861 7.94058 13.5781 7.94058 13.8192 8.17827C14.0603 8.41597 14.0603 8.80238 13.8192 9.04008H13.8179Z"
      fill="white" />
  </svg>
);

export const NoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 0C4.47801 0 0 4.47801 0 10C0 15.522 4.47801 20 10 20C15.522 20 20 15.522 20 10C19.9911 4.47801 15.5175 0.00444247 10 0Z"
      fill="#F65434" />
    <path d="M13.7625 6.23212C13.5892 6.05886 13.3093 6.05886 13.1361 6.23212L9.99971 9.3685L6.86333 6.23212C6.69007 6.05886 6.41019 6.05886 6.23694 6.23212C6.06368 6.40537 6.06368 6.68525 6.23694 6.8585L9.37332 9.99489L6.23694 13.1313C6.06368 13.3045 6.06368 13.5844 6.23694 13.7577C6.41019 13.9309 6.69007 13.9309 6.86333 13.7577L9.99971 10.6213L13.1361 13.7577C13.3093 13.9309 13.5892 13.9309 13.7625 13.7577C13.9357 13.5844 13.9357 13.3045 13.7625 13.1313L10.6261 9.99489L13.7625 6.8585C13.9357 6.68525 13.9357 6.40537 13.7625 6.23212Z"
      fill="white" />
  </svg>
);

export type CreditReportsType = Record<CreditReportTypes, CreditReportFile[]>;

interface TickWithLabelProps {
  label: string;
  isValid: boolean;
}

interface CreditReportPageProps {
  credit: Credit;
  setCredit: (nextCredit: Credit) => void;
  requiredDoctypeIds: number[];
  questions: SurveyQuestion[];
  guarantorDoctypes: Doctype[];
}

export const TickWithLabel = ({ label, isValid }: TickWithLabelProps) => {
  return (
    <div className={cls.tick}>
      {isValid ? <YesIcon /> : <NoIcon />}
      <div className={cls.tickLabel}>{label}</div>
    </div>
  );
};

export const CreditReportPage = ({
  credit, requiredDoctypeIds, questions, guarantorDoctypes,
}: CreditReportPageProps) => {
  const redirect = useRedirect();

  const [isSurveyCompleted, setIsSurveyCompleted] = useState<boolean>(false);
  const [reportFiles, setReportFiles] = useState<CreditReportsType>({
    [CreditReportTypes.EKATENA_REPORT]: [],
    [CreditReportTypes.BUREAU_REPORT]: [],
    [CreditReportTypes.SINECTA_REPORT]: [],
  });
  const [isGuarantorDocumentsCompleted, setGuarantorDocumentsCompleted] = useState<boolean>(false);

  const backTo = `/credit/${credit.id}?initial`;

  const submit = async () => {
    try {
      const creditRisk = await creditHttp.createCreditRisk(credit.id);
      redirect(`/credit-risk/${creditRisk.id}`);
    } catch (e: any) {
      showAxiosError(e);
    }
  };

  const cancel = () => void redirect(backTo);
  const checkSurveyAnswers = (value: boolean) => void setIsSurveyCompleted(value);

  useEffect(() => {
    if (questions.length === 0) {
      setIsSurveyCompleted(true);

      return;
    }

    const totalQuestions = questions
      .filter(question => question.isVisible)
      .reduce((total: Record<'questions' | 'answers', number>, question: SurveyQuestion) => {
        const { cols: answersCols } = question.rows[0] || {};
        const questionsLength = question.answers?.length || 0;
        const answersLength = answersCols?.filter((i: any) => !!i.customAnswer).length || 0;

        return {
          questions: total.questions + questionsLength,
          answers: total.answers + answersLength,
        };
      }, {
        questions: 0,
        answers: 0,
      });

    if (totalQuestions.questions === totalQuestions.answers && totalQuestions.answers > 0) {
      setIsSurveyCompleted(true);
    }
  }, [questions]);

  const uploadedDocumentsIds = useMemo(() => {
    return (credit.documents || [])
      .filter(d => !!d.files?.length || !!d.number || d.isOptionalChecked)
      .map(d => d.doctypeId) || [];
  }, [credit.documents]);

  useEffect(() => {
    if (credit) {
      setGuarantorDocumentsCompleted(!!credit.isGuarantorsRequired);
    }
  }, [credit?.isGuarantorsRequired]);

  const isReportsCompleted =
    !!reportFiles.EKATENA_REPORT?.length
    && !!reportFiles.BUREAU_REPORT?.length
    && !!reportFiles.SINECTA_REPORT?.length;
  const [guarantor] = credit.guarantors;

  const isClientValid = requiredDoctypeIds
    .every(id => uploadedDocumentsIds.filter(Boolean).includes(id));
  const hasClientGuarantor = !credit.isGuarantorsRequired
    || (credit.guarantors.length > 0 && isGuarantorDocumentsCompleted);

  const isSendButtonAvailable = isSurveyCompleted
    && isReportsCompleted
    && isClientValid
    && hasClientGuarantor;

  return (
    <InitialPage
      title="Send to Analysis" backTo={backTo} backText="Back to credit" gap={-10}
      headerRight={<RequestedAmount credit={credit} disabled={false} />}
    >
      <div className={cls.content}>
        <CreditReportSurvey
          questions={questions}
          checkSurveyAnswers={checkSurveyAnswers}
        />

        <CreditReportFiles
          creditId={credit.id}
          files={reportFiles}
          setReportFiles={setReportFiles}
        />

        <div className={cls.verification}>
          <CreditReportUser
            isClientValid={isClientValid}
            hasClientGuarantor={hasClientGuarantor}
            isSurveyCompleted={isSurveyCompleted}
            isReportsCompleted={isReportsCompleted}
            isGuarantorsRequired={!credit.isGuarantorsRequired}
          />
          <CreditReportGuarantor
            guarantor={guarantor}
            guarantorDoctypes={guarantorDoctypes?.filter(d => d.isSendToCredit) || []}
            setGuarantorDocumentsCompleted={setGuarantorDocumentsCompleted}
            isNotRequired={!credit.isGuarantorsRequired}
          />
        </div>

        <div className={cls.actions}>
          <Button className={cls.save}
            variant="contained"
            onClick={submit}
            disabled={!isSendButtonAvailable}>
            Send
          </Button>
          <Button className={cls.cancel} variant="contained" onClick={cancel}>
            Cancel
          </Button>
        </div>
      </div>
    </InitialPage>
  );
};
