import React, { useEffect, useState } from 'react';
import {
  TextField,
  ListProps,
  ReferenceField,
  Identifier,
  RaRecord,
  FunctionField,
  useRedirect,
  RowClickFunction,
  Link,
  useNotify,
} from 'react-admin';

import { cropHttp } from '@network/crop-http';
import {
  PhoneField,
  EmailField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Grid,
  ImageField,
  CommentsField,
  BooleanField,
  Forbidden,
  SelectArrayInput,
  DateRangeInput,
  ButtonLinkField,
  NullableBooleanInput,
  DateField,
} from '@components';
import { userStatusOptions, UserStatusField } from './UserStatusField';
import { axiosErrorToString, dateFormatter, formatHoursToPhrase } from '@utils';
import {
  CreditCrop,
  dealerTiersChoices,
  genderOptions, LoginTry,
  loginTryOptions,
  marriageOptions, moffinOptions,
  User,
} from '@types';
import { checkSuperadmin } from '@providers';
import { UserLoginBtn } from './UserLoginBtn';
import { useExporter, useRbacEdit, useRbacList } from '@hooks';
import { checkRbacPermission } from '@utils/check-rbac-permission';
import { stateHttp } from '@network/state-http';
import { GridList } from '@components/GridList/GridList';
import { UserCity } from './UserCity';
import { UserListActions } from './UserListActions';
import cls from './UserList.module.css';

export const UserList = (props: ListProps) => {
  const exporter = useExporter({
    filename: 'Verqor Farmers',
    props: {
      'id': {
        label: 'ID',
      },
      'code': {
        label: 'Verqor Code',
      },
      'status': {
        label: 'Status',
        prop: 'statusEn',
      },
      'fullName': {
        label: 'User',
      },
      'phone': {
        label: 'Phone',
        transform: 'phone',
      },
      'email': {
        label: 'Email',
      },
      'birthday': {
        label: 'Birthday',
        transform: 'date',
      },
      'buyers': {
        label: 'Buyers',
        manyProp: 'name',
      },
      'retailers': {
        label: 'Retailers',
        manyProp: 'name',
      },
      'associates': {
        label: 'Associates',
        manyProp: 'name',
      },
      'hubspotLink': {
        label: 'Hubspot',
        prop: 'hubspotLink',
      },
      'companyName': {
        label: 'Company',
      },
      'isCompany': {
        label: 'Is Company',
        transform: 'boolean',
      },
      'moffinBureauScore': {
        label: 'Bureau Score',
        transform: 'spaced',
      },
      'stateId': {
        label: 'State',
        prop: 'state.name',
      },
      'cityName': {
        label: 'City',
      },
      'rfc': {
        label: 'RFC',
      },
      'rfcCompany': {
        label: 'RFC (Company)',
      },
      'marriage': {
        label: 'Civil status',
      },
      'address': {
        label: 'Address',
      },
      'lastLoginTry': {
        label: 'Login Try',
        prop: 'lastLoginTry',
        transform: (loginTry: LoginTry | null) => {
          if (!loginTry) return '';
          const loginAt = dateFormatter.toDateTime(loginTry.createdAt, true);
          const succeedText = loginTry.isAdmin ? 'Is succeed by admin' : 'Is succeed';
          const phone = loginTry.login;

          return [loginAt, succeedText, phone].join(', ');
        },
      },
      'city': {
        label: 'Municipality',
        prop: 'location',
        transform: (location: {city: string}[]) =>
          location.map(loc => loc.city).filter(Boolean).join(', '),
      },
      'credit.creditCrops': {
        label: 'Financed Crop',
        prop: 'credit.creditCrops',
        transform: (crops: CreditCrop[]) =>
          crops.map(c => c.financedCrop?.nameMx).filter(Boolean).join(', '),
      },
      'mainBuyer.name': {
        label: 'Main Buyer',
        prop: 'mainBuyer.name',
      },
      'tier': {
        label: 'Main Buyer Tier',
        prop: 'tier',
      },
      'activation.activation': {
        label: 'Activation',
        prop: 'activation.activation',
      },
      'createdAt': {
        label: 'Registered At',
        transform: 'date',
      },
    },
  });

  const isSuperadmin = checkSuperadmin();
  const permissionUserLogin = checkRbacPermission('user/{id}/login-admin|ALL');
  const showLoginButton = isSuperadmin || permissionUserLogin;
  const rbacList = useRbacList();
  const rbacEdit = useRbacEdit();
  const redirect = useRedirect();
  const notify = useNotify();

  const [cropOptions, setCropOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const crops = cropHttp.getAll();
        const states = stateHttp.getAll();
        const [loadedCrops, loadedStates] = await Promise.all([crops, states]);

        setCropOptions(loadedCrops
          .sort((a, b) => a.nameMx.localeCompare(b.nameMx))
          .map(crop => ({
            id: crop.id,
            name: crop.nameEn || crop.nameMx || `Crop id: ${crop.id}`,
          })));
        setStateOptions(loadedStates
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(state => ({
            id: state.id,
            name: state.name || state.name || `State id: ${state.id}`,
          })),
        );
      } catch (e) {
        notify(axiosErrorToString(e));
      }
    })();
  }, []);

  if (!rbacList) {
    return <Forbidden />;
  }

  const userRowClick: RowClickFunction = (id: Identifier, resource: string, record: RaRecord) => {
    redirect(`/${resource}/${id}?initial`);
    return false;
  };

  return (
    <GridList {...props}
      editComponent={rbacEdit ? <UserListActions /> : undefined}
      exporter={exporter}
      filters={[
        ...Filters,
        <SelectArrayInput
          key="cropIds"
          source="cropIds"
          choices={cropOptions}
          alwaysOn
          className="MS-field"
          label="Crops"
          style={{ width: 268 }}
        />,
        <SelectArrayInput
          key="stateIds"
          source="stateIds"
          choices={stateOptions}
          alwaysOn
          className="MS-field"
          label="State"
          style={{ width: 268 }}
        />,
      ]}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Grid
        rowClick={userRowClick}
        defaultColumns={'code fullName status phone email hubspotLink createdAt isCompany sourceEn buyerTiers'.split(' ')}
        sx={{
          '& .column-history': { minWidth: 500 },
        }}
        className={cls.grid}
      >
        <FunctionField resource="user" source="code" sortBy="id" render={(record: any) => (
          <Link to={`/user/${record.id}?initial`}>
            <TextField source="code" sortBy="id" />
          </Link>
        )} />
        <FunctionField resource="user" source="fullName" render={(record: any) => (
          <Link to={`/user/${record.id}?initial`}>
            <TextField source="fullName" />
          </Link>
        )} />
        <FunctionField source="isRenewal" label="Eligible for Renewal" render={(record: any) => (
          <span>{record.isRenewal ? 'Yes' : 'No'}</span>
        )} />
        <FunctionField resource="user" source="lastLoginTry" label="Login try"
          render={(record: any) => {
            const loginAt = record?.lastLoginTry?.createdAt;
            const succeedText = record?.lastLoginTry?.isAdmin
              ? 'Is succeed by admin'
              : 'Is succeed';

            return (
              <div className={cls.loginTry}>
                <div className={cls.loginTryDate}>
                  {loginAt ? dateFormatter.toDateTime(loginAt, true) : null}
                </div>
                <div>
                  <span>{record?.lastLoginTry?.isSucceed ? succeedText : 'Is not succeed'}</span>
                  {record?.lastLoginTry?.isSucceed &&
                    <span className={cls.loginTryStatus}>{record?.lastLoginTry?.login}</span>
                  }
                </div>
              </div>
            );
          }}
        />
        <UserStatusField source="status" label="Status" />
        <TextField source="inactiveReason" label="Reason for inactivity" />
        <PhoneField source="phone" />
        <EmailField source="email" wrap />
        <DateField source="createdAt"
          label="Registered At"
          locales="es-ES"
          options={{ timeZone: 'UTC' }} />
        <TextField source="sourceEn" label="Channel" />
        <TextField source="gender" label="Gender" />
        <DateField source="birthday" />
        <TextField source="buyerNames" label="Buyers" sortable={false} />
        <TextField source="retailerNames" label="Retailers" sortable={false} />
        <TextField source="associateNames" label="Associates" sortable={false} />
        <ButtonLinkField source="hubspotLink" text="Hubspot" label="Hubspot" center />
        <TextField source="companyName" label="Company" />
        <BooleanField source="isCompany" label="Is Company" />
        <TextField source="moffinBureauScore" label="Bureau Score" />
        <CommentsField
          source="comments"
          partnerClass="User"
          label="Admin Notes"
          editable={rbacEdit}
          sortable={false}
        />
        <TextField source="mainBuyer.name" label="Main Buyer" />
        <TextField source="tier" label="Main Buyer Tier" />
        {isSuperadmin ? (
          <ReferenceField source="stateId" reference="state" label="State">
            <TextField source="name" />
          </ReferenceField>
        ) : (
          <TextField source="state.name" label="State" />
        )}
        <TextField source="cityName" label="City" />
        <TextField source="rfc" label="RFC" />
        <TextField source="rfcCompany" label="RFC (Company)" />
        <TextField source="marriage" label="Civil status" />
        <TextField source="address" label="Address" sortable={false} />
        <UserCity source="city" label="Municipality" sortable={false} />
        <TextField source="activation.activation" label="Activation" />
        {isSuperadmin ? (
          <ReferenceField reference="admin" source="responsibleAdminId" label="Manager">
            <TextField source="name" />
          </ReferenceField>
        ) : (
          <TextField source="responsibleAdmin.name" label="Responsible manager" />
        )}
        {showLoginButton ? <UserLoginBtn source="login" label="Login by User" /> : null}
        <ImageField source="avatar" width={100} round />
        <FunctionField
          source="history"
          label="History"
          render={(record: any) => {
            const user = record as User;
            const maxLogsCount = 3;
            const total = user.history?.length || 0;
            const logsCount = total - maxLogsCount < 0 ? 0 : total - maxLogsCount;
            const logs = user.history?.splice(0, maxLogsCount) || [];

            return (
              <div className={cls.historyWrap}>
                {logs.map((log) => {
                  const date = dateFormatter.toDateTime(log.createdAt, true);
                  const { statusBefore, statusAfter } = log;
                  const logMessage = statusBefore
                    ? `${statusBefore} → ${statusAfter} `
                    : `${statusAfter}`;

                  return (
                    <div key={log.id} className={cls.historyItem}>
                      {`${log.title} ${logMessage}`} / {date}
                    </div>
                  );
                })}
                {logsCount > 0 && (
                  <a href={`/#/user/${user.id}/7`} style={{ color: 'grey' }}>
                    and more logs...
                  </a>
                )}
              </div>
            );
          }}
          sortable={false}
        />
        <FunctionField
          source="hoursRegisterApplied"
          label="Register to Applying"
          render={(record: any) => {
            const user = record as User;

            return (
              <div>{formatHoursToPhrase(user?.hoursRegisterApplied)}</div>
            );
          }}
        />
      </Grid>
    </GridList>
  );
};

const Filters = [
  <TextInput key="q" source="q" label="Search" alwaysOn style={{ width: 270 }} />,
  <TextInput key="buyer" source="buyer" label="Buyer" alwaysOn style={{ width: 270 }} />,
  <SelectInput
    key="status"
    source="status"
    choices={userStatusOptions}
    alwaysOn
  />,
  <SelectInput key="gender" source="gender" choices={genderOptions} />,
  <SelectInput key="loginTry" source="loginTry" choices={loginTryOptions} />,
  <ReferenceInput
    key="responsibleAdminId"
    source="responsibleAdminId"
    reference="admin"
    label="Manager"
    allowEmpty
    perPage={1000}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <SelectInput optionText="name" label="Manager" style={{ minWidth: 180 }} />
  </ReferenceInput>,
  <SelectInput key="marriage" source="marriage" choices={marriageOptions} label="Civil status" />,
  <DateRangeInput
    key="createdAt"
    source="createdAt"
    label="Registered At"
    defaultValue={false}
    alwaysOn
  />,
  <TextInput key="city" source="city" label="Municipality" style={{ width: 180 }} />,
  <SelectArrayInput
    style={{ width: 180 }}
    key="tier"
    source="tier"
    label="Main Buyer Tier"
    choices={dealerTiersChoices}
    className={'MS-field'}
  />,
  <TextInput key="mainBuyer" source="mainBuyer" label="Main Buyer" style={{ width: 270 }} />,
  <NullableBooleanInput key="isRenewal" source="isRenewal" label="Eligible for Renewal" />,
  <SelectInput key="moffin" source="moffin" label="Moffin Profile" choices={moffinOptions} />,
  <NullableBooleanInput key="isDemo" source="isDemo" label="Show Demo" />,
];
