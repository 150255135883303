import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { clsx } from 'clsx';

import cls from './input-select.module.css';
import { useState } from 'react';

interface InputSelectProps {
  name: string;
  label: string;
  selectedValue: number | string | string[] | null;
  values: string[];
  options?: {value: string | number, label: string}[];
  onChange: (event: SelectChangeEvent<string | null>) => void;
  labelWidth?: number;
  error?: string;
  className?: string;
  centered?: boolean;
  multiple?: boolean;
  displayEmpty?: boolean;
}

export const InputSelect = ({
  label,
  selectedValue,
  values,
  options,
  onChange,
  name,
  labelWidth,
  error,
  className,
  centered,
  multiple,
  displayEmpty,
}: InputSelectProps) => {
  const [value, setValue] = useState<any>(selectedValue || '');
  const hasOptions = Array.isArray(options);
  const optionsLength = hasOptions ? options.length : values.length;

  return (
    <div className={cls._}>
      <label
        className={cls.label}
        style={{ minWidth: labelWidth ? labelWidth : 100 }}
      >
        {label}
      </label>
      <FormControl
        variant="standard"
        className={clsx(cls.form, className, centered && cls.centered)}
      >
        <Select
          name={name}
          value={optionsLength > 0 ? value : ''}
          onChange={e => {
            setValue(e.target.value);
            onChange(e);
          }}
          multiple={multiple}
          displayEmpty={displayEmpty}
        >
          {displayEmpty && <MenuItem>&nbsp;</MenuItem>}
          {hasOptions && options.map(o => (
            <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
          ))}
          {!hasOptions && values.map(value => (
            <MenuItem key={value} value={value}>{value}</MenuItem>
          ))}
        </Select>

        {error && <span className={cls.error}>{error}</span>}
      </FormControl>
    </div>
  );
};
